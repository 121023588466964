import React, { useState } from "react";
import Calendar from "react-calendar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Sidebar from "../components/CoachSidebar";
import "react-circular-progressbar/dist/styles.css";
import "react-calendar/dist/Calendar.css";
import "./CoachProfile.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const MainContent = ({ activeLink, onLinkClick }) => {
  return (
    <div className="billing-main-content">
      {/* Welcome Section */}
      <Link
        to="/payments"
        className={`stat ${
          activeLink === "Billing Information" ? "active" : ""
        }`}
        onClick={() => onLinkClick("Billing Information")}
      >
        <img
          src="/coachbilling.svg"
          alt="Billing Icon"
          className="billing-icon"
        />
        <h2 className="icon-text">Billing Information</h2>
      </Link>
      <Link
        to="/payments/balance"
        className={`stat ${activeLink === "Available Balance" ? "active" : ""}`}
        onClick={() => onLinkClick("Available Balance")}
      >
        <img
          src="/coachbalance.svg"
          alt="Coach-Balance Icon"
          className="coach-balance-icon"
        />
        <h2 className="icon-text">Available Balance</h2>
      </Link>
      <Link
        to="/payments/payment-method"
        className={`stat ${activeLink === "Payout Methods" ? "active" : ""}`}
        onClick={() => onLinkClick("Payout Methods")}
      >
        <img
          src="/coachpaymentmethod.svg"
          alt="Payment-Method Icon"
          className="payment-method-icon"
        />
        <h2 className="icon-text">Payout Methods</h2>
      </Link>
    </div>
  );
};

const RightContent = (props) => {
  const [activeSection, setActiveSection] = useState("personal");

  // Function to handle section click
  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  // Conditional styling for headers
  const getHeaderStyle = (section) => {
    return activeSection === section
      ? { color: "#4a93cf" }
      : { color: "#A0A0A0" };
  };
  const handleSectionChange1 = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === "checkbox" ? checked : value;
    props.onChange(name, updatedValue);
  };

  return (
    <div>
      <div className="billy-header-container">
        <Link
          className="billy-head"
          to="/coach-profile"
          onClick={() => handleSectionClick("personal")}
          style={getHeaderStyle("personal")}
        >
          Personal Information
        </Link>
        <Link
          className="billy-head"
          to="/coach-profile/education"
          onClick={() => handleSectionClick("education")}
          style={getHeaderStyle("education")}
        >
          Education
        </Link>
        <Link
          className="billy-head"
          to="/coach-profile/work"
          onClick={() => handleSectionClick("work")}
          style={getHeaderStyle("work")}
        >
          Work
        </Link>
      </div>
      <div className="right-main-contents">
        <div className="main-forms">
          {/* Full Name */}
          <div className="main-labels-header">
            <div className="labels-header">Full name</div>
            <input
              className="main-inputs-fields"
              type="text"
              name="fullName"
              onChange={handleSectionChange1}
              value={props.values.fullName || ""}
              placeholder="Enter your full name"
            />
          </div>

          {/* Country */}
          <div className="main-labels-header">
            <div className="labels-header">Pronouns</div>
            <input
              className="main-inputs-fields-row"
              type="text"
              name="pronoun"
              onChange={handleSectionChange1}
              value={props.values.pronoun || ""}
              placeholder="Enter your pronoun"
            />
          </div>

          {/* Address */}
          <div className="main-labels-header">
            <div className="labels-header">Address</div>
            <input
              className="main-inputs-fields"
              type="text"
              name="address"
              onChange={handleSectionChange1}
              value={props.values.address || ""}
              placeholder="Enter your address"
            />
          </div>

          {/* State */}
          <div className="main-labels-header">
            <div className="labels-header">Phone Number</div>
            <input
              className="main-inputs-fields-row"
              type="text"
              name="phone"
              onChange={handleSectionChange1}
              value={props.values.phone || ""}
              placeholder="(000) 000 - 0000"
            />
          </div>

          {/* City */}
          <div className="main-labels-header">
            <div className="labels-header">Email</div>
            <input
              className="main-inputs-fields"
              type="text"
              name="email"
              onChange={handleSectionChange1}
              value={props.values.email || ""}
              placeholder="Enter your email"
            />
          </div>

          {/* Zip Code */}
          <div className="main-labels-header">
            <div className="labels-header">Specialities</div>
            <input
              className="main-inputs-fields"
              type="text"
              name="specialities"
              onChange={handleSectionChange1}
              value={props.values.specialities || ""}
              placeholder="Enter your specialities"
            />
          </div>

          {/* Tax ID */}
          <div className="main-labels-header">
            <div className="labels-header">Bio</div>
            <textarea
              className="main-inputs-fields-text"
              type="text"
              name="bio"
              onChange={handleSectionChange1}
              value={props.values.bio || ""}
              placeholder="Tell me about yourself"
            />
          </div>

          {/* Submit Button */}
          <button className="update-button" onClick={props.onSubmit}>
            Update Profile
          </button>
        </div>
      </div>
    </div>
  );
};

const CoachProfile = () => {
  const [activeLink, setActiveLink] = useState("Billing Information");

  const [formValues, setFormValues] = useState({
    fullName: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    address: "",
    taxId: "",
    emailInvoices: false,
  });

  // Function to handle field changes
  const handleFieldChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    console.log("Form submitted with values:", formValues);
    // Add logic for form submission (e.g., API call)
  };

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  return (
    <div className="billing-dashboard">
      <Sidebar activeLink={activeLink} onLinkClick={handleLinkClick} />
      <MainContent activeLink={activeLink} onLinkClick={handleLinkClick} />
      <div>
        <RightContent
          values={formValues}
          onChange={handleFieldChange}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default CoachProfile;
