import React, { useState } from "react";
import Calendar from "react-calendar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Sidebar from "../components/CoachSidebar";
import "react-circular-progressbar/dist/styles.css";
import "react-calendar/dist/Calendar.css";
import "./CoachPaymentMethod.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const MainContent = ({ activeLink, onLinkClick }) => {
  return (
    <div className="billing-main-content">
      {/* Welcome Section */}
      <Link
        to="/payments"
        className={`stat ${
          activeLink === "Billing Information" ? "active" : ""
        }`}
        onClick={() => onLinkClick("Billing Information")}
      >
        <img
          src="/coachbilling.svg"
          alt="Billing Icon"
          className="billing-icon"
        />
        <h2 className="icon-text">Billing Information</h2>
      </Link>
      <Link
        to="/payments/balance"
        className={`stat ${activeLink === "Available Balance" ? "active" : ""}`}
        onClick={() => onLinkClick("Available Balance")}
      >
        <img
          src="/coachbalance.svg"
          alt="Coach-Balance Icon"
          className="coach-balance-icon"
        />
        <h2 className="icon-text">Available Balance</h2>
      </Link>
      <Link
        to="/payments/payment-method"
        className={`stat ${activeLink === "Payout Methods" ? "active" : ""}`}
        onClick={() => onLinkClick("Payout Methods")}
      >
        <img
          src="/coachpaymentmethod.svg"
          alt="Payment-Method Icon"
          className="payment-method-icon"
        />
        <h2 className="icon-text">Payout Methods</h2>
      </Link>
    </div>
  );
};

const RightContent = () => {
  return (
    <div className="dashboard-pay-content">
      <h2 className="pay-title">Pick Your Payout Method</h2>
      <p className="pay-subtitle">Sent in USD</p>
      <div className="payout-methods">
        <div className="payout-option">
          <div className="payment-methods-icon">
            <img src="/fast-pay.svg" alt="Book Icon" />
          </div>
          <div className="pay-details">
            <h3>Fast Pay</h3>
            <p>
              Visa or Mastercard credit card required
              <br />
              30 minutes or less
            </p>
          </div>
        </div>
        <div className="payout-option">
          <div className="payment-methods-icon">
            <img src="/bank-acct.svg" alt="Book Icon" />
          </div>
          <div className="pay-details">
            <h3>Bank Account</h3>
            <p>
              3-5 business days
              <br />
              No fees
            </p>
          </div>
        </div>
        <div className="payout-option">
          <div className="payment-methods-icon">
            <img src="/paypal.svg" alt="Book Icon" />
          </div>
          <div className="pay-details">
            <h3>PayPal</h3>
            <p>Link Required</p>
          </div>
        </div>
        <div className="payout-option">
          <div className="payment-methods-icon">
            <img src="/venmo.svg" alt="Book Icon" />
          </div>
          <div className="pay-details">
            <h3>Venmo</h3>
            <p>Link Required</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const CoachPaymentMethod = () => {
  const [activeLink, setActiveLink] = useState("Billing Information");

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  return (
    <div className="billing-dashboard">
      <Sidebar activeLink={activeLink} onLinkClick={handleLinkClick} />
      <MainContent activeLink={activeLink} onLinkClick={handleLinkClick} />
      <div>
        <RightContent />
      </div>
    </div>
  );
};

export default CoachPaymentMethod;
