import React, { useState } from "react";
import "./EditPassword.css";

const EditPassword = ({ onClose }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChangePassword = () => {
    // Clear previous messages
    setError("");
    setSuccess("");

    // Validate inputs
    if (!currentPassword || !newPassword || !confirmPassword) {
      setError("All fields are required.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("New password and confirmation do not match.");
      return;
    }
    if (newPassword.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }

    // Mock password change logic (replace with actual API call)
    console.log("Changing password...");
    console.log(`Current Password: ${currentPassword}`);
    console.log(`New Password: ${newPassword}`);

    setSuccess("Password changed successfully!");
  };

  return (
    <div className="edit-password">
      <div className="editpassword">
        <div className="editpasswordexiticon">
          <div className="editpasswordexiticon2" />
          <div
            className="editpasswordexiticon1"
            style={{ cursor: "pointer" }}
            onClick={onClose}
          >
            +
          </div>
        </div>
      </div>
      <div className="editpasswordmainbody">
        <div className="editpasswordgroup">
          <div className="editpasswordchangbody">
            <div className="editpasswordcurrentpassword">
              <div className="editpasswordnewpassword">Current Password</div>
              <div className="editpasswordcurrentpasswordbox">
                <div className="editpasswordcurrentpasswordbox1">
                  <input
                    className="editpasswordcurrentpasswordbox2"
                    type="password"
                    placeholder="***********"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  <img
                    className="editpasswordcurrentpasswordbox-icon"
                    alt=""
                    src="/editpasswordcurrentpasswordboxeye.svg"
                  />
                </div>
              </div>
            </div>
            <div className="editpasswordcurrentpassword">
              <div className="editpasswordnewpassword">New Password</div>
              <div className="editpasswordcurrentpasswordbox">
                <div className="editpasswordcurrentpasswordbox1">
                  <input
                    className="editpasswordcurrentpasswordbox2"
                    type="password"
                    placeholder="***********"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <img
                    className="editpasswordcurrentpasswordbox-icon"
                    alt=""
                    src="/editpasswordcurrentpasswordboxeye.svg"
                  />
                </div>
              </div>
            </div>
            <div className="editpasswordcurrentpassword">
              <div className="editpasswordnewpassword">Confirm Password</div>
              <div className="editpasswordcurrentpasswordbox">
                <div className="editpasswordcurrentpasswordbox1">
                  <input
                    className="editpasswordcurrentpasswordbox2"
                    type="password"
                    placeholder="***********"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <img
                    className="editpasswordcurrentpasswordbox-icon"
                    alt=""
                    src="/editpasswordcurrentpasswordboxeye.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="editpasswordchangebutton">
            <div
              className="editpasswordchangebuttontext"
              style={{ cursor: "pointer" }}
              onClick={handleChangePassword}
            >
              Change Password
            </div>
          </div>
          {error && <div className="editpassworderror">{error}</div>}
          {success && <div className="editpasswordsuccess">{success}</div>}
        </div>
      </div>
    </div>
  );
};

export default EditPassword;
