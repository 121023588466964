import React, { useState } from "react";
import Calendar from "react-calendar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Sidebar from "../components/CoachSidebar";
import "react-circular-progressbar/dist/styles.css";
import "react-calendar/dist/Calendar.css";
import "./CoachAvailableBalance.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const MainContent = ({ activeLink, onLinkClick }) => {
  return (
    <div className="billing-main-content">
      {/* Welcome Section */}
      <Link
        to="/payments"
        className={`stat ${
          activeLink === "Billing Information" ? "active" : ""
        }`}
        onClick={() => onLinkClick("Billing Information")}
      >
        <img
          src="/coachbilling.svg"
          alt="Billing Icon"
          className="billing-icon"
        />
        <h2 className="icon-text">Billing Information</h2>
      </Link>
      <Link
        to="/payments/balance"
        className={`stat ${activeLink === "Available Balance" ? "active" : ""}`}
        onClick={() => onLinkClick("Available Balance")}
      >
        <img
          src="/coachbalance.svg"
          alt="Coach-Balance Icon"
          className="coach-balance-icon"
        />
        <h2 className="icon-text">Available Balance</h2>
      </Link>
      <Link
        to="/payments/payment-method"
        className={`stat ${activeLink === "Payout Methods" ? "active" : ""}`}
        onClick={() => onLinkClick("Payout Methods")}
      >
        <img
          src="/coachpaymentmethod.svg"
          alt="Payment-Method Icon"
          className="payment-method-icon"
        />
        <h2 className="icon-text">Payout Methods</h2>
      </Link>
    </div>
  );
};

const RightContent = (props) => {
  const user = useSelector((state) => state.user.currentUser);
  const bookings = [
    {
      date: "09/31/2024",
      details: "Jason Johnson Introduction",
      amount: "$100.00",
    },
    {
      date: "09/28/2024",
      details: "Essay Review with Todd Taylor",
      amount: "$200.00",
    },
    {
      date: "09/27/2024",
      details: "Tom Smith Essay Workshop",
      amount: "$100.00",
    },
    {
      date: "09/25/2024",
      details: "Meeting with Todd Taylor",
      amount: "$100.00",
    },
    {
      date: "09/13/2024",
      details: "Essay Review with Tom Smith",
      amount: "$200.00",
    },
    {
      date: "09/11/2024",
      details: "Introduction",
      amount: "$100.00",
    },
  ];

  return (
    <div className="dashboard-main-content">
      {/* Community Statistics Section */}
      <div className="communities-stats">
        <div className="stat-item">
          <span className="description">Total Income Information</span>
          <p className="value">$400.00</p>
          <span className="currency">USD</span>
        </div>
        <div className="stat-item">
          <span className="description" style={{ marginTop: "5%" }}>
            Total Weekly Information
          </span>
          <p className="value">$100.00</p>
          <span className="currency">USD</span>
        </div>
      </div>

      <div className="welcome-news-section">
        <h2 className="coache-name">Session History</h2>
        <div className="upcoming-events">
          <table className="bookings-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Details</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking, index) => (
                <tr key={index}>
                  <td>{booking.date}</td>
                  <td>{booking.details}</td>
                  <td style={{ color: "#4A93CF" }}>{booking.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const CoachAvailableBalance = () => {
  const [activeLink, setActiveLink] = useState("Billing Information");

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  return (
    <div className="billing-dashboard">
      <Sidebar activeLink={activeLink} onLinkClick={handleLinkClick} />
      <MainContent activeLink={activeLink} onLinkClick={handleLinkClick} />
      <div>
        <RightContent />
      </div>
    </div>
  );
};

export default CoachAvailableBalance;
