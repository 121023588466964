import React, { useState, useEffect } from "react";
import Sidebar from "../components/CoachSidebar";
import "./CoachScholarshipPage.css";
import SCHOLARSHIPSCATEGORYPAGE from "./SCHOLARSHIPSCATEGORYPAGE";

const CoachScholarshipPage = () => {
  const [activeLink, setActiveLink] = useState("Scholarships");
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsSidebarVisible(true);
    } else {
      setIsSidebarVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="coachs-scholarship-dashboard">
      <div
        className={`schols-sidebar ${isSidebarVisible ? "visible" : "hidden"}`}
      >
        <Sidebar activeLink={activeLink} onLinkClick={handleLinkClick} />
      </div>
      <div className="scholarships-category-page">
        <SCHOLARSHIPSCATEGORYPAGE />
      </div>
    </div>
  );
};

export default CoachScholarshipPage;
