import React, { useState, useEffect } from "react";
import axios from "axios";
import Calendar from "react-calendar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Sidebar from "../components/CoachSidebar";
import "react-circular-progressbar/dist/styles.css";
import "react-calendar/dist/Calendar.css";
import "./CoachHomepage.css";
import { BASE_Server_URL } from "../requestMethods";
import { useSelector } from "react-redux";


const DashboardMainContent = () => {
  const user = useSelector((state) => state.user.currentUser);
  const [filteredCoaches, setFilteredCoaches] = useState([]);
  const [coachLoading, setCoachLoading] = useState(false);
  const [coach, setCoach] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const getCoach = async () => {
    try {
      const res = await publicRequest.get("/coach/" + user?.user?.id);
      setCoach(res.data);
      console.log("mxd c,dx", res.data);
    } catch {}
  };

  // const getCoaches = async () => {
  //   console.log("getCoaches called");
  //   try {
  //     setCoachLoading(true);
  //     const res = await axios.get(
  //       `${BASE_Server_URL}coach?page=${page}&limit=8`
  //     );

  //     //console.log('rfvnmd,s.de',res)
  //     const pageC = await res.data.totalPages;
  //     setCoaches(res.data.results);
  //     setFilteredCoaches(res.data.results);

  //     setPageCount(pageC);

  //     //console.log('rfvnmd,s.de',  res.data.results)
  //     setCoachLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  useEffect(() => {
    getCoach();
  }, []);

  console.log("filterw",coach);
  console.log("user",user);

  const events = [
    {
      date: "Sept 25",
      title: "Meeting with Tom Smith",
      time: "2:30pm - 3:30pm",
    },
    { date: "Sept 27", title: "Coach Workshop", time: "4:00pm - 5:30pm" },
    {
      date: "Sept 28",
      title: "Essay Review with Todd Taylor",
      time: "1:15pm - 2:30pm",
    },
  ];

  return (
    <div className="dashboard-main-content">
      {/* Welcome Section */}
      <div className="welcome-section">
        <h2>
          Welcome Back <span className="coach-name">Coach {user?.user?.name.split(" ")[0]}</span>
        </h2>
      </div>

      <div className="comm-stat">
        <h2>Community Statics</h2>
      </div>

      {/* Community Statistics Section */}
      <div className="community-stats">
        <div className="start-item">
          <i className="fas fa-star icon"></i>
          <p className="title">5 Star Rating</p>
          <span className="description">10 Reviews</span>
        </div>
        <div className="start-item">
          <p className="value">$400.00</p>
          <span className="currency">USD</span>
          <span className="description"  style={{marginTop: "5%"}}>Total Revenue Earned</span>
        </div>
        <div className="start-item">
          {/* <i className="fas fa-book icon"></i> */}
          <img src="/booksCoach.svg" alt="Book Icon" className="book-icon" />
          <p className="value2">6</p>
          <span className="description">Total Bookings</span>
        </div>
      </div>

      <div className="comm-stat2">
        <h3>Upcoming Events</h3>
      </div>
      <div className="upcoming-events">
        {events.map((event, index) => (
          <div key={index} className="up-event">
            <div className="event-date">
              <div className="event-month">{event.date.split(" ")[0]}</div>
              <div className="event-day">{event.date.split(" ")[1]}</div>
            </div>
            <div className="event-details">
              <h4>{event.title}</h4>
              <p>{event.time}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const RightContent = () => {
  const progress = 80;
  return (
    <div className="right-content">
      <div className="profile-completion">
        <div className="profile-header">
          <a className="profile-title">Complete your profile</a>
          <div className="progress-bar">
            <div className="progress-circle">
              <CircularProgressbar
                value={progress}
                text={`${progress}%`}
                styles={buildStyles({
                  textSize: "16px",
                  pathColor: "#4CAF50", // Green color
                  textColor: "#4CAF50",
                  trailColor: "#f4f4f4",
                })}
              />
            </div>
          </div>
        </div>
        <div className="profile-comp">
          <p>
            <b>Maximize your impact!</b> A complete profile boosts your
            visibility.
          </p>
        </div>
        <ul>
          <li>
            <span className="completed-task">Verify your email</span>
          </li>
          <li>
            <span className="pending-task">
              <span>Setup Coaching Calendar</span> — Connect with students
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

const CustomCalendar = () => {
  const [date, setDate] = useState(new Date());

  // Highlighted dates
  const highlightedDates = [
    new Date(2024, 8, 11), // 11th September 2024
    new Date(2024, 8, 13),
    new Date(2024, 8, 25),
    new Date(2024, 8, 27),
    new Date(2024, 8, 31),
  ];

  const isHighlighted = (date) => {
    return highlightedDates.some(
      (highlightedDate) =>
        date.getFullYear() === highlightedDate.getFullYear() &&
        date.getMonth() === highlightedDate.getMonth() &&
        date.getDate() === highlightedDate.getDate()
    );
  };

  // Custom weekday format for "S M T W T F S"
  const formatShortWeekday = (locale, date) =>
    new Intl.DateTimeFormat(locale, { weekday: "short" })
      .format(date)
      .slice(0, 1); // Extract the first character of the short weekday

  return (
    <div className="calendars-container">
      <Calendar
        onChange={setDate}
        value={date}
        formatShortWeekday={formatShortWeekday} // Custom weekday format
        tileContent={({ date, view }) =>
          view === "month" && isHighlighted(date) ? (
            <div className="dot"></div>
          ) : null
        }
      />
    </div>
  );
};

// Main Dashboard component combining everything
const CoachDashboard = () => {
  const [activeLink, setActiveLink] = useState("Home");

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  return (
    <div className="coachs-dashboard">
      <Sidebar activeLink={activeLink} onLinkClick={handleLinkClick} />
      <DashboardMainContent />
      <div>
        <RightContent />
        <div className="datet">
          <CustomCalendar />
        </div>
      </div>
    </div>
  );
};

export default CoachDashboard;
