import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import Sidebar from "../components/CoachSidebar";
import "react-circular-progressbar/dist/styles.css";
import "react-calendar/dist/Calendar.css";
import "./CoachCalendar.css";

const DashboardMainContent = () => {
  const [schedule, setSchedule] = useState({
    Sunday: { active: false, timeSlots: [] },
    Monday: { active: false, timeSlots: [] },
    Tuesday: { active: true, timeSlots: [{ start: "13:30", end: "17:00" }] },
    Wednesday: { active: false, timeSlots: [] },
    Thursday: { active: false, timeSlots: [] },
    Friday: { active: false, timeSlots: [] },
    Saturday: { active: false, timeSlots: [] },
  });

  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("recurring");

  useEffect(() => {
    const path = location.pathname.split("/").pop(); // Get the last segment of the URL
    setSelectedTab(path); // Update selectedTab based on the current route
  }, [location.pathname]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    navigate(`/calendar/${tab}`);
  };

  const toggleDay = (day) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: { ...prev[day], active: !prev[day].active },
    }));
  };

  const addTimeSlot = (day) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        timeSlots: [...prev[day].timeSlots, { start: "", end: "" }],
      },
    }));
  };

  const updateTimeSlot = (day, index, field, value) => {
    setSchedule((prev) => {
      const updatedTimeSlots = [...prev[day].timeSlots];
      updatedTimeSlots[index][field] = value;
      return {
        ...prev,
        [day]: {
          ...prev[day],
          timeSlots: updatedTimeSlots,
        },
      };
    });
  };

  const removeTimeSlot = (day, index) => {
    setSchedule((prev) => {
      const updatedTimeSlots = [...prev[day].timeSlots];
      updatedTimeSlots.splice(index, 1); // Remove the specific time slot
      return {
        ...prev,
        [day]: {
          ...prev[day],
          timeSlots: updatedTimeSlots,
        },
      };
    });
  };

  return (
    <div className="app">
      <div className="availability-section">
        <h2>Availability</h2>
      </div>
      <div className="availability-navbutton">
        <span
          className="availability-navlink"
          onClick={() => handleTabClick("recurring")}
          style={{
            color: selectedTab === "recurring" ? "#393939" : "#7A7A7A",
            fontWeight: selectedTab === "recurring" ? 700 : 500,
          }}
        >
          Recurring
        </span>
        <span
          className="availability-navlink"
          onClick={() => handleTabClick("weekly")}
          style={{
            color: selectedTab === "weekly" ? "#393939" : "#7A7A7A",
            fontWeight: selectedTab === "weekly" ? 700 : 500,
          }}
        >
          Weekly
        </span>
      </div>
      <div className="schedule">
        {Object.keys(schedule).map((day) => (
          <div key={day} className="day-row">
            <div className="day-header">
              <label className="day-label">
                {day}
                <span className="toggle-switch">
                  <input
                    type="checkbox"
                    className="toggle-checkbox"
                    checked={schedule[day].active}
                    onChange={() => toggleDay(day)}
                  />
                  <span className="slider"></span>
                </span>
              </label>
            </div>
            {schedule[day].active && (
              <div className="time-slots">
                {schedule[day].timeSlots.map((slot, index) => (
                  <div key={index} className="time-slot">
                    <input
                      type="time"
                      value={slot.start}
                      onChange={(e) =>
                        updateTimeSlot(day, index, "start", e.target.value)
                      }
                    />
                    <span>-</span>
                    <input
                      type="time"
                      value={slot.end}
                      onChange={(e) =>
                        updateTimeSlot(day, index, "end", e.target.value)
                      }
                    />
                    <span
                      className="cancel-slot-button"
                      onClick={() => removeTimeSlot(day, index)}
                    >
                      x
                    </span>
                  </div>
                ))}
                <button
                  className="add-slot-button"
                  onClick={() => addTimeSlot(day)}
                >
                  +
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const CustomCalendar = () => {
  const [date, setDate] = useState(new Date());

  // Highlighted dates
  const highlightedDates = [
    new Date(2024, 8, 11), // 11th September 2024
    new Date(2024, 8, 13),
    new Date(2024, 8, 25),
    new Date(2024, 8, 27),
    new Date(2024, 8, 31),
  ];

  const isHighlighted = (date) => {
    return highlightedDates.some(
      (highlightedDate) =>
        date.getFullYear() === highlightedDate.getFullYear() &&
        date.getMonth() === highlightedDate.getMonth() &&
        date.getDate() === highlightedDate.getDate()
    );
  };

  // Custom weekday format for "S M T W T F S"
  const formatShortWeekday = (locale, date) =>
    new Intl.DateTimeFormat(locale, { weekday: "short" })
      .format(date)
      .slice(0, 1); // Extract the first character of the short weekday

  return (
    <div className="calendar-container">
      <Calendar
        onChange={setDate}
        value={date}
        formatShortWeekday={formatShortWeekday} // Custom weekday format
        tileContent={({ date, view }) =>
          view === "month" && isHighlighted(date) ? (
            <div className="dot"></div>
          ) : null
        }
      />
    </div>
  );
};

const ScheduledEvents = () => {
  const events = [
    {
      date: "Sept 27",
      title: "Coach Workshop",
      time: "2:30pm - 3:30pm",
    },
  ];

  function addToGoogleCalendar(event) {
    const { title, date, time } = event;

    const eventStartDate = `2024-09-${date.split(" ")[1]}T14:30:00`;
    const eventEndDate = `2024-09-${date.split(" ")[1]}T15:30:00`;

    const eventDescription = `${title} set for ${time}`;
    const eventLocation = "Online";
    const eventTimeZone = `${time} America/New_York time`;

    const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      title
    )}&dates=${encodeURIComponent(eventStartDate)}/${encodeURIComponent(
      eventEndDate
    )}&details=${encodeURIComponent(
      eventDescription
    )}&location=${encodeURIComponent(eventLocation)}&ctz=${encodeURIComponent(
      eventTimeZone
    )}`;

    window.open(googleCalendarUrl, "_blank");
  }

  return (
    <div>
      <div>
        {events.map((event, index) => (
          <button
            key={index}
            className="add-to-calendar"
            onClick={() => addToGoogleCalendar(event)}
          >
            <span className="plus-sign">+</span>Add to Calendar
          </button>
        ))}
      </div>
      <div className="comm-stat2">
        <h3>Scheduled</h3>
      </div>
      <div className="upcoming-events">
        {events.map((event, index) => (
          <div key={index} className="up-event">
            <div className="event-date">
              <div className="event-month">{event.date.split(" ")[0]}</div>
              <div className="event-day">{event.date.split(" ")[1]}</div>
            </div>
            <div className="event-details">
              <h4>{event.title}</h4>
              <p>{event.time}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const CoachCalendar = () => {
  const [activeLink, setActiveLink] = useState("Home");

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  return (
    <div className="coach-dashboard">
      <Sidebar activeLink={activeLink} onLinkClick={handleLinkClick} />
      <DashboardMainContent />
      <div>
        <div className="datet2">
          <CustomCalendar />
          <ScheduledEvents />
        </div>
      </div>
    </div>
  );
};

export default CoachCalendar;
