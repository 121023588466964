import React, { useState, useCallback } from "react";
import "./Footer.css";
import Tooltip from "@mui/material/Tooltip";
import NewsletterForm from "./NewsletterForm";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const [mobileTooltip, setMobileTooltip] = useState(null);

  const handleTooltipToggle = (index) => {
    setMobileTooltip(mobileTooltip === index ? null : index);
  };

  const isMobile = window.innerWidth <= 768;

  // const contactLink = useCallback(() => {
  //   navigate("/contact");
  // }, [navigate]);

  return (
    <div className="footer">
      <div className="footer1">
        <div className="footer-grid-row">
          <div className="footer-menu">
            <img className="footerlogo-icon" alt="" src="/footerlogo.svg" />
            <div className="footer-links">
              <div className="footerlinknav">
                <span className="nav-link">info@scholistapp.com</span>
              </div>
              {/*  <div className="footerlinknav">
                <span className="nav-link">+1 234 567 9830</span>
              </div>
              <div className="footerlinknav">
                <span className="nav-link">Address</span>
              </div> */}
            </div>
            <div className="icon-wrapper">
              <a
                href="https://instagram.com/scholistapp?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footericoninstagram"
                  alt="Instagram"
                  src="/footericoninstagram.svg"
                />
              </a>
              <a
                href="https://www.facebook.com/Scholistapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footericonfacbook"
                  alt="Facebook"
                  src="/footericonfacbook.svg"
                />
              </a>
              <a
                href="https://twitter.com/scholistapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footericontwitter"
                  alt="Twitter"
                  src="/footericontwitter.svg"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/scholist/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footericonlinkedin"
                  alt="LinkedIn"
                  src="/footericonlinkedin.svg"
                />
              </a>
              <a
                href="https://www.tiktok.com/@scholistapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footericontwitter"
                  alt="Twitter"
                  src="/footericontiktok.svg"
                />
              </a>
              <a
                href="https://www.youtube.com/@scholistapp4239"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footericonyoutube"
                  alt="YouTube"
                  src="/footericonyoutube.svg"
                />
              </a>
            </div>
          </div>
          <div className="footer-menu-parent">
            <div className="footer-menu1">
              <span className="students">Students</span>
              {/* <Tooltip title="Coming Soon" arrow>
                <span>
                  <div className="headernav-link37">
                    <div
                      style={{ cursor: "pointer" }}
                      className="nav-active"
                    >{`Students `}</div>
                  </div>
                </span>
              </Tooltip> */}
              <div className="footer-links">
                <div className="main-footer-link">
                  <a
                    href="/scholarships-category-page"
                    style={{ color: "white" }}
                    className="footer-link"
                  >
                    Scholarships
                  </a>
                </div>
                <div className="main-footer-link">
                  {/* <span className="footer-link">Community</span> */}
                  {isMobile ? (
                    <span
                      onClick={() => handleTooltipToggle(1)}
                      style={{ cursor: "pointer" }}
                    >
                      Community{" "}
                      {mobileTooltip === 1 && (
                        <span className="tooltip-content">Coming Soon</span>
                      )}
                    </span>
                  ) : (
                    <Tooltip title="Coming Soon" arrow>
                      <span
                        style={{ cursor: "pointer" }}
                        className="nav-active"
                      >
                        Community
                      </span>
                    </Tooltip>
                  )}
                </div>
                <div className="main-footer-link">
                  <a
                    href="/mainservices"
                    style={{ color: "white" }}
                    className="footer-link"
                  >
                    Services
                  </a>
                </div>
                <div className="main-footer-link">
                  {/* <span
                    className="footer-link"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Resources
                  </span> */}
                  {isMobile ? (
                    <span
                      onClick={() => handleTooltipToggle(1)}
                      style={{ cursor: "pointer" }}
                    >
                      Resources{" "}
                      {mobileTooltip === 1 && (
                        <span className="tooltip-content">Coming Soon</span>
                      )}
                    </span>
                  ) : (
                    <Tooltip title="Coming Soon" arrow>
                      <span
                        style={{ cursor: "pointer" }}
                        className="nav-active"
                      >
                        Resources
                      </span>
                    </Tooltip>
                  )}
                  {/* <Tooltip title="Coming Soon" arrow>
                    <span>
                      <div className="headernav-link37">
                        <div
                          style={{ cursor: "pointer" }}
                          className="nav-active"
                        >{`Resources `}</div>
                      </div>
                    </span>
                  </Tooltip> */}
                </div>
              </div>
            </div>
            <div className="footer-menu2">
              <span className="students">Legal</span>
              {/* <Tooltip title="Coming Soon" arrow>
                <span>
                  <div className="headernav-link37">
                    <div
                      style={{ cursor: "pointer" }}
                      className="nav-active"
                    >{`Legal `}</div>
                  </div>
                </span>
              </Tooltip> */}
              <div className="footer-links">
                <div className="main-footer-link">
                  {/* <span className="footer-link">Privacy policy</span> */}
                  <a
                    href="https://scholistapp.notion.site/Scholist-Privacy-Policy-137c666c50f745fa8676b3083448899f?pvs=25"
                    style={{ color: "white" }}
                    className="footer-link"
                  >
                    Privacy policy
                  </a>
                </div>
                <div className="main-footer-link">
                  {/* <span className="footer-link">Blog</span> */}
                  {isMobile ? (
                    <span
                      onClick={() => handleTooltipToggle(1)}
                      style={{ cursor: "pointer" }}
                    >
                      Blog{" "}
                      {mobileTooltip === 1 && (
                        <span className="tooltip-content">Coming Soon</span>
                      )}
                    </span>
                  ) : (
                    <Tooltip title="Coming Soon" arrow>
                      <span
                        style={{ cursor: "pointer" }}
                        className="nav-active"
                      >
                        Blog
                      </span>
                    </Tooltip>
                  )}
                </div>
                <div className="main-footer-link">
                  {/* <span className="footer-link">Terms of Service</span> */}
                  <a
                    href="https://scholistapp.notion.site/Scholist-Terms-of-Service-5c12bc657bbb4796a8c90a4e62146513"
                    style={{ color: "white" }}
                    className="footer-link"
                  >
                    Terms of Service
                  </a>
                </div>
                <div className="main-footer-link">
                  {/* <span
                    className="footer-link"
                    style={{ whiteSpace: "nowrap", cursor: "default" }}
                  >
                    {" "}
                    Do Not Sell My Personal Info
                  </span> */}

                  <Tooltip
                    title="Coming Soon"
                    arrow
                    PopperProps={{
                      modifiers: [
                        {
                          name: "preventOverflow",
                          options: {
                            boundary: "window",
                          },
                        },
                      ],
                    }}
                  >
                    <span>
                      <div className="headernav-link37">
                        <div
                          style={{
                            cursor: "pointer",
                            whiteSpace: "nowrap",
                            touchAction: "manipulation", 
                          }}
                          className="nav-active"
                        >
                          {`Do Not Sell My Personal Info`}
                        </div>
                      </div>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="footer-menu2">
              <span className="students">Company</span>
              {/* <Tooltip title="Coming Soon" arrow>
                <span>
                  <div className="headernav-link37">
                    <div
                      style={{ cursor: "pointer" }}
                      className="nav-active"
                    >{`Company `}</div>
                  </div>
                </span>
              </Tooltip> */}
              <div className="footer-links">
                <div className="main-footer-link">
                  {/* <span className="footer-link">About Us</span> */}
                  {isMobile ? (
                    <span
                      onClick={() => handleTooltipToggle(1)}
                      style={{ cursor: "pointer" }}
                    >
                      About Us{" "}
                      {mobileTooltip === 1 && (
                        <span className="tooltip-content">Coming Soon</span>
                      )}
                    </span>
                  ) : (
                    <Tooltip title="Coming Soon" arrow>
                    <span>
                      <div className="headernav-link37">
                        <div
                          style={{ cursor: "pointer" }}
                          className="nav-active"
                        >{`About Us`}</div>
                      </div>
                    </span>
                  </Tooltip>
                  )}
                </div>
                <div className="main-footer-link">
                  {/* <span className="footer-link">Partners</span> */}
                  {isMobile ? (
                    <span
                      onClick={() => handleTooltipToggle(1)}
                      style={{ cursor: "pointer" }}
                    >
                      Partners{" "}
                      {mobileTooltip === 1 && (
                        <span className="tooltip-content">Coming Soon</span>
                      )}
                    </span>
                  ) : (
                    <Tooltip title="Coming Soon" arrow>
                    <span>
                      <div className="headernav-link37">
                        <div
                          style={{ cursor: "pointer" }}
                          className="nav-active"
                        >{`Partners`}</div>
                      </div>
                    </span>
                  </Tooltip>
                  )}
                </div>
                <div className="main-footer-link">
                  {/* <span className="footer-link">Careers</span> */}
                  {isMobile ? (
                    <span
                      onClick={() => handleTooltipToggle(1)}
                      style={{ cursor: "pointer" }}
                    >
                      Careers{" "}
                      {mobileTooltip === 1 && (
                        <span className="tooltip-content">Coming Soon</span>
                      )}
                    </span>
                  ) : (
                    <Tooltip title="Coming Soon" arrow>
                    <span>
                      <div className="headernav-link37">
                        <div
                          style={{ cursor: "pointer" }}
                          className="nav-active"
                        >{`Careers`}</div>
                      </div>
                    </span>
                  </Tooltip>
                  )}
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">
                    <a
                      // href="mailto: info@scholistapp.com"
                      href="/contact"
                      className="footer-link"
                      style={{ color: "white" }}
                    >
                      Contact
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <NewsletterForm />
          </div>
        </div>
        <div className="footer-legal">
          <span className="scholist-all-rights">
            © 2022 Scholist. All rights reserved.
          </span>
          <div className="disclaimer-cookies">Disclaimer Cookies</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
