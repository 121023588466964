
import "antd/dist/antd.min.css";
//import { Checkbox, Input } from "antd";
import "./CoachFilters.css";
import { useState, useEffect  } from "react";


import axios from 'axios';


import debounce from 'lodash.debounce';
import { BASE_Server_URL } from "../requestMethods";




// Retrieve the Google Maps API key from environment variables

const CoachFilters = (props) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  
const allServiceOptions = [
  { name: "Essay Review", value: "Essay Review", checked: false },
  { name: "Resume Review", value: "Resume Help", checked: false },
  { name: "General Scholarship Consulting", value: "Scholarship Services", checked: false },
  { name: "FAFSA filling assistance",  value: "FAFSA Assistance", checked: false },
  { name: "Interview Prep", value: "Interview Prep", checked: false },
  { name: "College Planning", value: "College Planning", checked: false },
 
]




const allCoachDetails = [
  { name: 5 , value: "5", checked: false },
  { name: 4, value: "4", checked: false },
  { name: 3, value: "3", checked: false },
  { name: 2,  value: "2", checked: false },
 
 
]


const allLanguageOptions = [
  { name: "English", value: "English", checked: false },
  { name: "French", value: "French", checked: false },
  { name: "Spanish", value: "Spanish", checked: false },
  { name: "Yoruba",  value: "Yoruba", checked: false },
  { name: "Hausa",  value: "Hausa", checked: false },
  { name: "English", value: "English", checked: false },

  { name: "Portugese", value: "Portugese", checked: false },
  { name: "Spanish", value: "Spanish", checked: false },
  { name: "Yoruba",  value: "Yoruba", checked: false },
  { name: "Hausa",  value: "Hausa", checked: false },
  { name: "English", value: "English", checked: false },
  { name: "French", value: "French", checked: false },
  { name: "Spanish", value: "Spanish", checked: false },
  { name: "Yoruba",  value: "Yoruba", checked: false },
  { name: "Hausa",  value: "Hausa", checked: false },
 
 
]


var allLocationsOptions = [
  { name: "United States", value: "United States", checked: false },
  { name: "United Kingdom", value: "United Kingdom", checked: false },
  { name: "Nigeria", value: "Nigeria", checked: false },
  { name: "Mexixo", value: "mexico", checked: false },
  { name: "Canada", value: "canada", checked: false },
  

 
 
]

const Checkbox = ({ isChecked, label, handleChange, index, value, name}) => {

  //     if(name == 'citizenships'){
  //       console.log('citizenships', isChecked)
  //     }
  //    // console.log("isCfghredwskhbr", isChecked)
      return (
      
  
  
  <div className="frame-wrapper3">
  <div className="check-box-outline-blank-parent">
    {/* <Checkbox className="clear-all" /> */}
    <input
            type="checkbox"
            id={`checkbox-${index}`}
            checked={isChecked}
            onChange={ event => {handleChange(event, index)}}
            name={name}
            value= {value}
            className="filterCheckBox"
          />
  
  <label htmlFor={`checkbox-${index}`} className="based-on-your">{label}</label>
  
  
    {/* <div className="based-on-your">Architecture</div> */}
  </div>
  </div>
  
  
  
  
      )
    }

    const RatingStars = ({ count }) => {
      // Ensure count is between 1 and 5
      const normalizedCount = Math.min(Math.max(count, 1), 5);
    
      const starIcons = Array.from({ length: normalizedCount }, (_, index) => (
        <span key={index} role="img" aria-label="star">
          ⭐️
        </span>
      ));
    
      return <div>{starIcons}</div>;
    };
    
    const  CheckboxCoachDetails= ({ isChecked, label, handleChange, index, value, name}) => {

      //     if(name == 'citizenships'){
      //       console.log('citizenships', isChecked)
      //     }
      //    // console.log("isCfghredwskhbr", isChecked)
          return (
          
      
      
      <div className="frame-wrapper3">
      <div className="check-box-outline-blank-parent">
        {/* <Checkbox className="clear-all" /> */}
        <input
                type="checkbox"
                id={`checkbox-${index}`}
                checked={isChecked}
                onChange={ event => {handleChange(event, index)}}
                name={name}
                value= {value}
                className="filterCheckBox"
              />
      
      <label htmlFor={`checkbox-${index}`} className="based-on-your"><div className="ratinglabel3">{label} <RatingStars count={label}/></div></label>
      
      
        {/* <div className="based-on-your">Architecture</div> */}
      </div>
      </div>
      
      
      
      
          )
        }

   
  
const [sort, setSorts] = useState('newest');




  const [filters, setFilters] = useState({});
  const [serviceinfo, setServiceInfo] = useState({});
  const [serviceFilters, setServiceFilters] = useState({});
  const [serviceFiltersinfo, setServiceFiltersInfo] = useState({
    services: [],
    response: [],
  });


  const [filterCoachDetails, setFilterCoachDetails] = useState({});
  const [coachDetailsinfo, setCoachDetailsInfo] = useState({});


  const [coachDetailsFilter, setCoachDetailsFilter] = useState({});
  const [coachDetailsFilterinfo, setCoachDetailsFilterInfo] = useState({
    coachDetails: [],
    response: [],
  });

  const [filterLanguages, setFilterLanguages] = useState({});
  const [languagesinfo, setLanguagesInfo] = useState({});


  const [languagesFilter, setLanguagesFilter] = useState({});
  const [languagesFilterinfo, setLanguagesFilterInfo] = useState({
    languages: [],
    response: [],
  });



  const [filterLocations, setFilterLocations] = useState({});
  const [locationsInfo, setLocationsInfo] = useState({});


  const [locationsFilter, setLocationsFilter] = useState({});
  const [locationsFilterinfo, setLocationsFilterInfo] = useState({
    locations: [],
    response: [],
  });

  const [serviceOptions, setServiceOptions] = useState(allServiceOptions)
  const [coachDetailOptions, setCoachDetailOptions] = useState(allCoachDetails)
  const [languageOptions, setLanguageOptions] = useState(allLanguageOptions)
  const [locationOptions, setLocationOptions] = useState(allLocationsOptions)



  const [budgetMin, setBudgetMin] = useState('');
  const [budgetMax, setBudgetMax] = useState('');
  const [budgetFilterMax, setBudgetFilterMax] = useState('');
  const [budgetFilterMin, setBudgetFilterMin] = useState('');

  const handleChange = (e,i) => {
    // Destructuring

    e.stopPropagation(); // Prevent event propagation
    if(e.target.name == 'services'){
      updateCheckStatus(i);
    }

    if(e.target.name == 'coachDetail'){
      updateCheckStatus1(i);
    }

    if(e.target.name == 'languages'){
      updateCheckStatusLanguages(i);
    }

    if(e.target.name == 'locations'){
      updateCheckStatusLocations(i);
    }
   
   
   
   
    const { value, checked } = e.target;

    console.log('value', e)
     
    const { services } = serviceFiltersinfo;
    const { coachDetails } = coachDetailsFilterinfo;
    const { languages } = languagesFilterinfo;
    const { locations } = locationsFilterinfo;
    //console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      console.log('checked', checked)
      if(e.target.name == 'services'){
    /*     setFilters({
          ...filters,
          //[e.target.name]: value
          services: [...services, value]
        }) 
        
        setServiceInfo({
          services: [...services, value],
          //response: [...services, value],
        });
      */

        setServiceFilters({
          ...serviceFilters,
          //[e.target.name]: value
          services: [...services, value]
        }) 
        
        setServiceFiltersInfo({
          services: [...services, value],
          //response: [...services, value],
        });
     
      }
      if(e.target.name == 'coachDetail'){
        setCoachDetailsFilter({
          ...coachDetailsFilter,
          //[e.target.name]: value
          coachDetails: [...coachDetails, value]
          
        }) 
         
        setCoachDetailsFilterInfo({
          coachDetails: [...coachDetails, value],
          //response: [...coachDetails, value],
        });
       
      }
      if(e.target.name == 'languages'){
        setLanguagesFilter({
         ...languagesFilter,
          //[e.target.name]: value
          languages: [...languages, value]
        }) 
         
        setLanguagesFilterInfo({
          languages: [...languages, value],
          //response: [...coachDetails, value],
        }); 
     
      }
      if(e.target.name == 'locations'){
        setLocationsFilter({
         ...locationsFilter,
          //[e.target.name]: value
          locations: [...locations, value]
        }) 
         
        setLocationsFilterInfo({
          locations: [...locations, value],
          //response: [...coachDetails, value],
        }); 
     
      }

      
     
    }

    // Case 2  : The user unchecks the box
    else {
      console.log("unchecked")
      if(e.target.name == 'services'){
      //   setFilters({
      //     //...filters,
      //     services: services.filter((e) => e !== value),
      //     response: services.filter((e) => e !== value),
      //   }) 
        
      //   setServiceInfo({
      //     services: services.filter((e) => e !== value),
      //     response: services.filter((e) => e !== value),
      // });


      setServiceFilters({
        services: services.filter((e) => e !== value),
        response: services.filter((e) => e !== value),
      }) 
      
      setServiceFiltersInfo({
       
        services: services.filter((e) => e !== value),
        response: services.filter((e) => e !== value),
        //response: [...services, value],
      });
      }

      if(e.target.name == 'coachDetail'){
        setCoachDetailsFilter({
          coachDetails: coachDetails.filter((e) => e !== value),
          response: coachDetails.filter((e) => e !== value),
        }) 
         
        setCoachDetailsFilterInfo({
          coachDetails: coachDetails.filter((e) => e !== value),
          response: coachDetails.filter((e) => e !== value),
        }); 
      }

      if(e.target.name == 'languages'){
        
        setLanguagesFilter({
         
          languages: languages.filter((e) => e !== value),
          response: languages.filter((e) => e !== value),
        }) 
         
        setLanguagesFilterInfo({
          languages: languages.filter((e) => e !== value),
          response: languages.filter((e) => e !== value),
        }); 
      }

      
      if(e.target.name == 'locations'){
        
        setLocationsFilter({
         
          locations: locations.filter((e) => e !== value),
          response: locations.filter((e) => e !== value),
        }) 
         
        setLocationsFilterInfo({
          locations: locations.filter((e) => e !== value),
          response: locations.filter((e) => e !== value),
        }); 
      }
      
    }

  };

  const updateCheckStatus = index => {
    setServiceOptions(
      serviceOptions.map((serviceOption, currentIndex) =>
        currentIndex === index
          ? { ...serviceOption, checked: !serviceOption.checked }
          : serviceOption
      )
    )
 
 
    
 
    // or
    // setToppings([
    //   ...toppings.slice(0, index),
    //   { ...toppings[index], checked: !toppings[index].checked },
    //   ...toppings.slice(index + 1),
    // ]);
  }
  const updateCheckStatus1 = index => {
   setCoachDetailOptions(
     coachDetailOptions.map((coachDetailOption, currentIndex) =>
       currentIndex === index
         ? { ...coachDetailOption, checked: !coachDetailOption.checked }
         : coachDetailOption
     )
   )
 }
 
 
 const updateCheckStatusLanguages = index => {
   setLanguageOptions(
     languageOptions.map((languageOption, currentIndex) =>
       currentIndex === index
         ? { ...languageOption, checked: !languageOption.checked }
         :languageOption
     )
   )
 }
 
 const updateCheckStatusLocations = index => {
   setLocationOptions(
     locationOptions.map((locationOption, currentIndex) =>
       currentIndex === index
         ? { ...locationOption, checked: !locationOption.checked }
         :locationOption
     )
   )
 }


 const budgetFilters = (event) =>{  
  if(event.target.name == 'budgetMax'){
    setBudgetFilterMax(event.target.value); 
  }  
  if(event.target.name == 'budgetMin'){
    setBudgetFilterMin(event.target.value); 
  }  
 
 }

 useEffect(() =>{
  // console.log('egdvbshnakm',serviceFilters)
  // console.log('egdvbsh bhygkl nakm',coachDetailsFilter)
  // console.log('egdvbsh bhygnxkl nakm',languagesFilter)
  // console.log('egdvbsh bhygnxkl nakm',locationsFilter)
  // console.log('egdvbsh bhygnxkl nakm',budgetFilterMin)
  // console.log('egdvbsh bhygnxkl nakm',budgetFilterMax)




 }, [serviceFilters, coachDetailsFilter, languagesFilter, locationsFilter, budgetFilterMin, budgetFilterMax])

 const [searchTerm, setSearchTerm] = useState('');
 const [suggestions, setSuggestions] = useState([]);
 const [loading, setLoading] = useState(false);
 const [error, setError] = useState(null);


//  const Google_Map_Api = process.env.GOOGLE_MAPS_API_KEY;

 
//  //const API_KEY = 'AIzaSyB1GfqChRIciJi3fL5DoqX7btjEf2vKmz0';
//  const AUTOCOMPLETE_API_URL = `https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${Google_Map_Api}`;

 //const PROXY_URL = '${BASE_Server_URL}proxy'; // Update this with your actual proxy server URL.

 const PROXY_URL = `${BASE_Server_URL}proxy`;

//  const debouncedFetchSuggestions = debounce(async (inputValue) => {
//    setLoading(true);
//    setError(null);
//    try {
//      // const response = await axios.get(`${AUTOCOMPLETE_API_URL}&input=${inputValue}`);
//      // const { predictions } = response.data;
//      // const locationSuggestions = predictions.map((prediction) => prediction.description);


//      const response = await axios.get(`${PROXY_URL}/?input=${inputValue}`);
//      const { predictions } = response.data;
//      const locationSuggestions = predictions.map((prediction) => prediction.description);


//      setSuggestions(locationSuggestions);
//      setLoading(false);
//    } catch (error) {
//      console.error('Error fetching location suggestions:', error);
//      setError('Error fetching suggestions. Please try again later.');
//      setLoading(false);
//    }
//  }, 300);


 const debouncedFetchSuggestions = debounce(async (inputValue) => {
  setLoading(true);
  setError(null);
  try {
      const response = await axios.get(`${PROXY_URL}/?input=${inputValue}`);
      const { predictions } = response.data;

      // Filter predictions to include only countries
      const countryPredictions = predictions.filter(prediction =>
          prediction.types.includes('country')
      );

      const countrySuggestions = countryPredictions.map((prediction) => prediction.description);
      setSuggestions(countrySuggestions);
      setLoading(false);
  } catch (error) {
      console.error('Error fetching country suggestions:', error);
      setError('Error fetching suggestions. Please try again later.');
      setLoading(false);
  }
}, 300);


 useEffect(()=>{
    // Debounce the API call to avoid excessive requests
      debouncedFetchSuggestions(searchTerm);
 }, [searchTerm])



 useEffect(() => {
   allLocationsOptions = []
 
   //const majorsList = []
   console.log('mag', allLocationsOptions)
 
   for (let i = 0; i < suggestions.length; i++) {
     //console.log(scholarships[i])
    // console.log(scholarships[i].majors)
 
     // console.log(scholarships[i].majors[h])
     
       if(
         // !majorsList.includes(
         // {name: scholarships[i].majors[h], value:scholarships[i].majors[h], checked:false}
         // )
         !allLocationsOptions.some(allLocations=> allLocations.name === suggestions[i]))
         {
           allLocationsOptions.push(
           { name: suggestions[i], value: suggestions[i], checked: false }
         )
           //scholarships[i].majors[h])
       }
     
   }
 console.log(allLocationsOptions)
 
   //setAllMajors(allMajors)
 
   setLocationOptions(allLocationsOptions)
 
   //console.log(allMajors)
 }, [suggestions])



 const handleInputChange = (event) => {
   const value = event.target.value;
   setSearchTerm(value);

   // Call the debounced function instead of making immediate requests
   //debouncedFetchSuggestions(value);
 };


 //const clearAlldata 
 const [clearAlldata, setClearAlldata] = useState(false);
  


 const clearAll = () => {
  //if()
 
    //setServiceOptions(serviceOptions.map(serviceOption => ({ ...serviceOption, checked: false })))


    const updatedData3 = serviceOptions.map(item => ({ ...item, checked: false }));
    setServiceOptions(updatedData3);
    setFilters((prevFilters) => ({
      ...prevFilters,
      services: [] // Reset the amounts to an empty array
    }));
    setServiceFilters((prevFilters) => ({
      ...prevFilters,
      services: [] // Reset the amounts to an empty array
    }));
    
    setServiceFiltersInfo((prevFilters) => ({
      ...prevFilters,
      services: [] // Reset the amounts to an empty array
    })); 
  

    //setServiceOptions(serviceOptions.map(serviceOption => ({ ...serviceOption, checked: false })))


    const updatedData4 = coachDetailOptions.map(item => ({ ...item, checked: false }));
    setCoachDetailOptions(updatedData4);
    setFilterCoachDetails((prevFilters) => ({
      ...prevFilters,
      coachDetails: [] // Reset the amounts to an empty array
    }));

    setCoachDetailsFilter((prevFilters) => ({
      ...prevFilters,
      coachDetails: [] // Reset the amounts to an empty array
    }));
    setCoachDetailsFilterInfo((prevFilters) => ({
      ...prevFilters,
      coachDetails: [] // Reset the amounts to an empty array
    })); 


    const updatedData5 = languageOptions.map(item => ({ ...item, checked: false }));
    setLanguageOptions(updatedData5);
    setFilterLanguages((prevFilters) => ({
      ...prevFilters,
      languages: [] // Reset the amounts to an empty array
    }));
    setLanguagesFilter((prevFilters) => ({
      ...prevFilters,
      languages: [] // Reset the amounts to an empty array
    }));
    setLanguagesFilterInfo((prevFilters) => ({
      ...prevFilters,
      languages: [] // Reset the amounts to an empty array
    })); 
  

 
    //setServiceOptions(serviceOptions.map(serviceOption => ({ ...serviceOption, checked: false })))


    const updatedData6 = locationOptions.map(item => ({ ...item, checked: false }));
    setLocationOptions(updatedData6);
    setFilterLocations((prevFilters) => ({
      ...prevFilters,
      locations: [] // Reset the amounts to an empty array
    }));
    setLocationsFilter((prevFilters) => ({
      ...prevFilters,
      locations: [] // Reset the amounts to an empty array
    }));
   
    setLocationsFilterInfo((prevFilters) => ({
      ...prevFilters,
     locations: [] // Reset the amounts to an empty array
    })); 
  

  
  setBudgetFilterMax(''); 
  setBudgetFilterMin(''); 
  setBudgetMax(''); 
  setBudgetMin(''); 


  setClearAlldata(true);

 // console.log()
 
}

useEffect(() => {
  console.log('clear dtaa ghbwnmkdddn')


  const filtersS = { 
  

    serviceFilters,
    coachDetailsFilter,
    languagesFilter,
    locationsFilter,
    budgetFilterMax,
    budgetFilterMin


    // filters,
    // filterCoachDetails,
    // filterLanguages





   }
props.FilterData(filtersS) 

setClearAlldata(false)


}, [clearAlldata])


 
  return (
    <div className="scholarship-filters" data-animate-on-scroll>
      <div className="coach-filters-inner">
        {/* <div className="clear-all-group">
          <div className="clear-all1">Clear All</div>
          <div className="clear-all1">Refine</div>
          <b className="clear-all1">X</b>
        </div> */}
         <div className="clear-all-parent">
        <div className="clear-all" onClick={()=> clearAll()} >Clear All</div>
        {/* <div className="clear-all">Refine</div> */}
        <b className="clear-all" onClick={()=> props.handleCloseModal()} >X</b>
      </div>
      </div> 

     
      <div className="categories-parent">
       


    <div className="categories">Categories</div>
    {serviceOptions.map((serviceOption, index) => (
        <Checkbox
           key={serviceOption.name}
           isChecked={serviceOption.checked}
           handleChange={ (e) => handleChange(e, index)}
           label={serviceOption.name}
          index={index} 
          value= {serviceOption.value}
          name = 'services'
          />
      ))}

<div className="categories">Coach Details</div>
    {coachDetailOptions.map((serviceOption, index) => (
        <CheckboxCoachDetails
           key={serviceOption.name}
           isChecked={serviceOption.checked}
           handleChange={ (e) => handleChange(e, index)}
           label={serviceOption.name}
          index={index} 
          value= {serviceOption.value}
          name = 'coachDetail'
          />
      ))}

<div className="categories">Languages</div>
<div className="languagesFilterBox">
    {languageOptions.map((serviceOption, index) => (
        <Checkbox
           key={serviceOption.name}
           isChecked={serviceOption.checked}
           handleChange={ (e) => handleChange(e, index)}
           label={serviceOption.name}
          index={index} 
          value= {serviceOption.value}
          name = 'languages'
          />
      ))}
    </div>

 <div className="categories">Budget</div>
<div className="frame-wrapper50">
          <div className="frame-parent1">
            <div className="min-parent">
              <div className="min">Min</div>
              <input
                className="frame-input"
                type="text"
                style={{ width: "81px" }}
                size="middle"
                placeholder="$  Any"
                name="budgetMin" 
                onChange={budgetFilters}
              />
            </div>
            <div className="min-parent">
              <div className="min">Max</div>
              <input
                className="frame-input"
                type="text"
                style={{ width: "81px" }}
                size="middle"
                placeholder="$  Any"
                name="budgetMax"
                onChange={budgetFilters}
              />
            </div>
          </div>
        </div>

        <div className="categories">Locations</div>
        <div className="frame-wrapper19">
        {/* <input className="frame-child" type="text" placeholder="Search" /> */}
        <input type="text"
         value={searchTerm}
         onChange={handleInputChange}
        placeholder="Type a location..."
       // className='filtersearch'
       className="frame-child"
        />
      </div>
    {locationOptions.map((serviceOption, index) => (
        <Checkbox
           key={serviceOption.name}
           isChecked={serviceOption.checked}
           handleChange={ (e) => handleChange(e, index)}
           label={serviceOption.name}
          index={index} 
          value= {serviceOption.value}
          name = 'locations'
          />
      ))}



      </div>

      <button onClick={()=> {
        const filtersS = { 
            // categoryFilters, 
            // amountFilters,
            // gradeLevelFilters,
            // locationFilters,
            // majorFilters,
            // citizenshipFilters

            serviceFilters,
            coachDetailsFilter,
            languagesFilter,
            locationsFilter,
            budgetFilterMax,
            budgetFilterMin


           }
        props.FilterData(filtersS) }
        }>Apply</button>
    </div>
  );
};

export default CoachFilters;
