import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import HOMEPAGE from "./pages/HOMEPAGE";
import SavedScholarship from "./pages/SavedScholarships";
import AppliedScholarship from "./pages/AppliedScholarship";
import BasedOnMajor from "./pages/BasedOnMajor";
import CoachPage from "./pages/CoachPage";
import CoachCalendar from "./pages/CoachCalendar";
import CoachCalendarWeekly from "./pages/CoachCalendarWeekly";
import CoachHomePage from "./pages/CoachHomepage";
import CoachBookingsPage from "./pages/CoachBookingsPage";
import CoachPaymentMethod from "./pages/CoachPaymentMethod";
import CoachPaymentPage from "./pages/CoachPaymentPage";
import CoachProfile from "./pages/CoachProfile";
import CoachScholarshipPage from "./pages/CoachScholarshipPage";
import CoachAvailableBalance from "./pages/CoachAvailableBalance";
import SCHOLARSHIPSDESCRIPTION1 from "./pages/SCHOLARSHIPSDESCRIPTION1";
import Dashboard from "./pages/Dashboard";
import Communities from "./pages/Communities";
import BasedOnProfile from "./pages/BasedOnProfile";
import Popular from "./pages/Popular";
import Services from "./pages/Services";
import MainServices from "./pages/MainService";
import SCHOLARSHIPSCATEGORYPAGE from "./pages/SCHOLARSHIPSCATEGORYPAGE";
import ContactSection from "./pages/Contact";
//import EDUCATIONPROFILE from "./components/EDUCATIONPROFILE";
//import STUDENTACTIVITIESPROFILE from "./pages/STUDENTACTIVITIESPROFILE";
//import PERSONALPROFILE from "./pages/PERSONALPROFILE";
import Login from "./pages/Login";
import LoginMobile from "./pages/LoginMobile";
import EMAILSIGNUP from "./pages/EMAILSIGNUP";
import EMAILSIGNUPMobile from "./pages/EmailSignupMobile";
import COACHEMAILSIGNUP from "./pages/COACHEMAILSIGNUP";
import CoachEmailSignUpMobile from "./pages/CoachEmailSignUpMobile";
import ProfileForm from "./pages/ProfileForm";
import GOOGLESIGNUP from "./pages/GOOGLESIGNUP";
import WelcomeSignUpMobile from "./pages/WelcomeSignUpMobile";
import Welcome from "./pages/Welcome";
import { useEffect, useState } from "react";
import LoggedInNavBar from "./components/LoggedInNavBar";
import LoggedInCoachNavBar from "./components/LoggedInCoachNavbar";
import NavBar from "./components/NavBar";
import Profile from "./pages/Profile";
import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";

// function App() {
//   const action = useNavigationType();
//   const location = useLocation();
//   const pathname = location.pathname;

//   useEffect(() => {
//     if (action !== "POP") {
//       window.scrollTo(0, 0);
//     }
//   }, [action, pathname]);

//   useEffect(() => {
//     let title = "";
//     let metaDescription = "";

//     switch (pathname) {
//       case "/":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/saved-scholarship":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/applied-scholarship":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/based-on-major":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/coach-page":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/scholarships-description1":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/dashboard":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/communities":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/based-on-profile":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/popular":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/services":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/scholarships-category-page":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/education-profile":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/student-activities-profile":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/profile":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/login":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/signup":
//         title = "";
//         metaDescription = "";
//         break;
//       case "/onboarding-4":
//         title = "";
//         metaDescription = "";
//         break;
//     }

//     if (title) {
//       document.title = title;
//     }

//     if (metaDescription) {
//       const metaDescriptionTag = document.querySelector(
//         'head > meta[name="description"]'
//       );
//       if (metaDescriptionTag) {
//         metaDescriptionTag.content = metaDescription;
//       }
//     }
//   }, [pathname]);

//   return (
//     <Routes>
//       <Route path="/" element={<HOMEPAGE />} />
//       <Route path="/saved-scholarship" element={<SavedScholarship />} />
//       <Route path="/applied-scholarship" element={<AppliedScholarship />} />
//       <Route path="/based-on-major" element={<BasedOnMajor />} />
//       <Route path="/coach-page/:id" element={<CoachPage />} />
//       <Route
//         path="/scholarships-description1"
//         element={<SCHOLARSHIPSDESCRIPTION1 />}
//       />
//       <Route path="/dashboard" element={<Dashboard />} />
//       <Route path="/communities" element={<Communities />} />
//       <Route path="/based-on-profile" element={<BasedOnProfile />} />
//       <Route path="/popular" element={<Popular />} />
//       <Route path="/services" element={<Services />} />
//       <Route
//         path="/scholarships-category-page"
//         element={<SCHOLARSHIPSCATEGORYPAGE />}
//       />
//       <Route path="/education-profile" element={<EDUCATIONPROFILE />} />
//       <Route
//         path="/student-activities-profile"
//         element={<STUDENTACTIVITIESPROFILE />}
//       />
//       <Route path="/profile" element={<PERSONALPROFILE />} />
//       <Route path="/login" element={<Login />} />
//       <Route path="/signup" element={<SIGNUP />} />
//       <Route path="/onboarding-4" element={<Welcome />} />
//     </Routes>
//   );
// }
// export default App;

import { useSelector } from "react-redux";
import ScholarshipMatches from "./pages/ScholarshipMatches";
import CoachBookmark from "./pages/CoachBookmark";
import ViewProfile from "./pages/ViewProfile";
import CoachAvailabilityCalendar from "./pages/CoachAvailabilityCalendar";
import MyMessages from "./pages/MyMessages";
import MyNotifications from "./pages/MyNotifications";
import MyBookings from "./pages/MyBookings";
import Settings from "./pages/Settings";
import WelcomeSignUp from "./pages/WelcomeSignUp";
import CoachSignUp from "./pages/CoachSignUp";
import CoachSignUpMobile from "./pages/CoachSignUpMobile";
import WelcomePath from "./pages/WelcomePath";
import WelcomePathMobile from "./pages/WelcomePathMobile";
import MessageView from "./pages/MessageView";
import Pay from "./pages/Pay";
import Success from "./pages/Success";
import CoachTimeSlot from "./pages/CoachTimeSlot";
import Support from "./pages/Support";
import Footer from "./components/Footer";

const isMobile = () => {
  return window.innerWidth <= 768;
};

function App() {
  const user = useSelector((state) => state.user.currentUser);
  const profileCompleted = useSelector(
    (state) => state.userProfile.profileCompleted
  );

  const [isMobileDevice, setIsMobileDevice] = useState(isMobile());

  useEffect(() => {
    const handleResize = () => {
      setIsMobileDevice(isMobile());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const location = useLocation();
  const hideFooterOnPaths = [
    "/login",
    "/signup",
    "/loginmobile",
    "/coachsignupmobile",
    "/coachsignupwithemailmobile",
    "/signupwithemail",
    "/signupwithemailmobile",
  ];
  const shouldHideFooter = hideFooterOnPaths.includes(location.pathname);

  const Layout = () => {
    return (
      // <div>
      //   <NavBar/>
      //    <Outlet/>
      //   {/* <Footer/> */}

      // </div>

      <>
        <NavBar />
        <Outlet />
        <Footer />
      </>
    );
  };

  const Layout1 = () => {
    return (
      <>
        <LoggedInNavBar />
        <Outlet />
        <Footer />
      </>
    );
  };

  const Layout2 = () => {
    return (
      <>
        <NavBar />
        <Outlet />
      </>
    );
  };

  const Layout3 = () => {
    return (
      <>
        <LoggedInCoachNavBar />
        <Outlet />
        <Footer />
      </>
    );
  };

  const Layout4 = () => {
    return (
      <>
        <LoggedInCoachNavBar />
        <Outlet />
      </>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: user ? (
        user?.user?.role === "coach" ? (
          <Layout3 />
        ) : (
          <Layout1 />
        )
      ) : (
        <Layout />
      ),
      children: [
        {
          path: "/",
          element: <HOMEPAGE />,
        },
      ],
    },
    {
      path: "/",
      element: user ? (
        user?.user?.role === "coach" ? (
          <Layout3 />
        ) : (
          <Layout1 />
        )
      ) : (
        <Layout />
      ),
      children: [
        {
          path: "/profile",
          element: user ? (
            <Profile />
          ) : (
            <Navigate to={isMobileDevice ? "/loginmobile" : "/login"} />
          ),
        },
        {
          path: "/scholarship-matches",
          element: <ScholarshipMatches />,
        },
        {
          path: "/saved-scholarship",
          element: <SavedScholarship />,
        },
        {
          path: "/applied-scholarship",
          element: <AppliedScholarship />,
        },
        {
          path: "/based-on-major",
          element: <BasedOnMajor />,
        },
        {
          path: "/coach-page/:id",
          element: <CoachPage />,
        },
        {
          path: "/scholarships-description1/:id",
          element: <SCHOLARSHIPSDESCRIPTION1 />,
        },
        {
          path: "/dashboard",
          element: user ? (
            <Dashboard />
          ) : (
            <Navigate to={isMobileDevice ? "/loginmobile" : "/login"} />
          ),
        },
        {
          path: "/communities",
          element: <Communities />,
        },
        {
          path: "/based-on-profile",
          element: <BasedOnProfile />,
        },
        {
          path: "/popular",
          element: <Popular />,
        },
        {
          path: "/mainservices",
          element: <MainServices />,
        },
        {
          path: "/services",
          element: <Services />,
        },
        {
          path: "/success",
          element: <Success />,
        },
        {
          path: "/bookmarkedcoaches",
          element: <CoachBookmark />,
        },
        {
          path: "/scholarships-category-page",
          element: <SCHOLARSHIPSCATEGORYPAGE />,
        },
        {
          path: "/viewprofile",
          element: <ViewProfile />,
        },
        {
          path: "/coachavailability",
          element: <CoachAvailabilityCalendar />,
        },
        {
          path: "/welcome",
          element: <CoachHomePage />,
          element: user ? (
            <CoachHomePage />
          ) : (
            <Navigate to={isMobileDevice ? "/loginmobile" : "/login"} />
          ),
        },
        {
          path: "/calendar/recurring",
          element: <CoachCalendar />,
          element: user ? (
            <CoachCalendar />
          ) : (
            <Navigate to={isMobileDevice ? "/loginmobile" : "/login"} />
          ),
        },
        {
          path: "/calendar/weekly",
          element: <CoachCalendarWeekly />,
          element: user ? (
            <CoachCalendarWeekly />
          ) : (
            <Navigate to={isMobileDevice ? "/loginmobile" : "/login"} />
          ),
        },
        {
          path: "/bookings",
          element: <CoachBookingsPage />,
          element: user ? (
            <CoachBookingsPage />
          ) : (
            <Navigate to={isMobileDevice ? "/loginmobile" : "/login"} />
          ),
        },
        {
          path: "/mymessages",
          element: <MyMessages />,
        },
        {
          path: "/payments",
          element: <CoachPaymentPage />,
        },
        {
          path: "/coach-profile",
          element: <CoachProfile />,
        },
        {
          path: "/scholarships",
          element: <CoachScholarshipPage />,
        },
        {
          path: "/payments/balance",
          element: <CoachAvailableBalance />,
        },
        {
          path: "/payments/payment-method",
          element: <CoachPaymentMethod />,
        },
        {
          path: "/notifications",
          element: <MyNotifications />,
        },
        {
          path: "/mybookings",
          element: <MyBookings />,
        },

        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "/messageview",
          element: <MessageView />,
        },
        {
          path: "/pay/:coachId/:serviceId",
          element: <Pay />,
        },
        {
          path: "/coachtimeslot/:intent",
          element: <CoachTimeSlot />,
        },
        {
          path: "/support",
          element: <Support />,
        },
      ],
    },
    {
      path: "/",
      element: user ? (
        user?.user?.role === "coach" ? (
          <Layout3 />
        ) : (
          <Layout1 />
        )
      ) : (
        <Layout2 />
      ),
      children: [
        {
          path: "/contact",
          element: <ContactSection />,
        },

        // {
        //   path:"/education-profile",
        //    element:<EDUCATIONPROFILE />
        // },
        // {
        //   path:"/student-activities-profile",
        //   element:<STUDENTACTIVITIESPROFILE />
        // },
        // {
        //   path:"/profile",
        //   element:<PERSONALPROFILE />
        // },

        {
          path: "/login",
          element: user ? (
            user?.user?.role === "coach" ? (
              <Navigate to="/welcome" />
            ) : (
              <Navigate to="/dashboard" />
            )
          ) : (
            <Login />
          ),
        },
        // {
        //   path: "/login",
        //   element: user ? <Navigate to="/dashboard" /> : <Login />,
        // },
        {
          path: "/loginmobile",
          element: user ? <Navigate to="/dashboard" /> : <LoginMobile />,
        },
        {
          path: "/signupwithemail",
          element: user ? <Navigate to="/profile" /> : <EMAILSIGNUP />,
        },
        {
          path: "/signupwithemailmobile",
          element: user ? <Navigate to="/profile" /> : <EMAILSIGNUPMobile />,
        },
        {
          path: "/profilefill",
          element: <ProfileForm />,
        },
        {
          path: "/coachsignupwithemail",
          element: user ? <Navigate to="/profilefill" /> : <COACHEMAILSIGNUP />,
        },
        {
          path: "/coachsignupwithemailmobile",
          element: user ? <Navigate to="/profilefill" /> : <CoachEmailSignUpMobile />,
        },
        {
          path: "/signupwithgoogle",
          element: user ? <Navigate to="/profile" /> : <GOOGLESIGNUP />,
        },
        {
          path: "/welcomesignup",
          element: user ? <Navigate to="/profile" /> : <WelcomeSignUp />,
        },
        {
          path: "/welcomesignupmobile",
          element: user ? <Navigate to="/profile" /> : <WelcomeSignUpMobile />,
        },
        {
          path: "/coachsignup",
          element: user ? <Navigate to="/onboarding-4" /> : <CoachSignUp />,
        },
        {
          path: "/coachsignupmobile",
          element: user ? <Navigate to="/onboarding-4" /> : <CoachSignUpMobile />,
        },
        {
          path: "/welcomepath",
          element: user ? <Navigate to="/onboarding-4" /> : <WelcomePath />,
        },
        {
          path: "/welcomepathmobile",
          element: user ? (
            <Navigate to="/onboarding-4" />
          ) : (
            <WelcomePathMobile />
          ),
        },

        {
          path: "/onboarding-4",
          element: <Welcome />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
