import React, { useState } from "react";
import Calendar from "react-calendar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Sidebar from "../components/CoachSidebar";
import "react-circular-progressbar/dist/styles.css";
import "react-calendar/dist/Calendar.css";
import "./CoachPaymentPage.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const MainContent = ({ activeLink, onLinkClick }) => {
  return (
    <div className="billing-main-content">
      {/* Welcome Section */}
      <Link
        to="/payments"
        className={`stat ${
          activeLink === "Billing Information" ? "active" : ""
        }`}
        onClick={() => onLinkClick("Billing Information")}
      >
        <img
          src="/coachbilling.svg"
          alt="Billing Icon"
          className="billing-icon"
        />
        <h2 className="icon-text">Billing Information</h2>
      </Link>
      <Link
        to="/payments/balance"
        className={`stat ${activeLink === "Available Balance" ? "active" : ""}`}
        onClick={() => onLinkClick("Available Balance")}
      >
        <img
          src="/coachbalance.svg"
          alt="Coach-Balance Icon"
          className="coach-balance-icon"
        />
        <h2 className="icon-text">Available Balance</h2>
      </Link>
      <Link
        to="/payments/payment-method"
        className={`stat ${activeLink === "Payout Methods" ? "active" : ""}`}
        onClick={() => onLinkClick("Payout Methods")}
      >
        <img
          src="/coachpaymentmethod.svg"
          alt="Payment-Method Icon"
          className="payment-method-icon"
        />
        <h2 className="icon-text">Payout Methods</h2>
      </Link>
    </div>
  );
};

const RightContent = (props) => {
  const handleSectionChange1 = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === "checkbox" ? checked : value;
    props.onChange(name, updatedValue);
  };

  return (
    <div>
      <h2 className="bill-head">Billing Information</h2>
      <div className="right-main-content">
        <div className="main-form">
          {/* Full Name */}
          <div className="main-label-header">
            <div className="label-header">Full Name</div>
            <input
              className="main-input-fields"
              type="text"
              name="fullName"
              onChange={handleSectionChange1}
              value={props.values.fullName || ""}
              placeholder="Enter your full name"
            />
          </div>

          {/* Country */}
          <div className="main-label-header">
            <div className="label-header">Country</div>
            <input
              className="main-input-fields"
              type="text"
              name="country"
              onChange={handleSectionChange1}
              value={props.values.country || ""}
              placeholder="Enter your country"
            />
          </div>

          {/* State */}
          <div className="main-label-header">
            <div className="label-header">State</div>
            <input
              className="main-input-fields"
              type="text"
              name="state"
              onChange={handleSectionChange1}
              value={props.values.state || ""}
              placeholder="Enter your state"
            />
          </div>

          {/* City */}
          <div className="main-label-header">
            <div className="label-header">City</div>
            <input
              className="main-input-fields"
              type="text"
              name="city"
              onChange={handleSectionChange1}
              value={props.values.city || ""}
              placeholder="Enter your city"
            />
          </div>

          {/* Zip Code */}
          <div className="main-label-header">
            <div className="label-header">Zip Code</div>
            <input
              className="main-input-fields"
              type="text"
              name="zipCode"
              onChange={handleSectionChange1}
              value={props.values.zipCode || ""}
              placeholder="Enter your zip code"
            />
          </div>

          {/* Address */}
          <div className="main-label-header">
            <div className="label-header">Address</div>
            <input
              className="main-input-fields"
              type="text"
              name="address"
              onChange={handleSectionChange1}
              value={props.values.address || ""}
              placeholder="Enter your address"
            />
          </div>

          {/* Tax ID */}
          <div className="main-label-header">
            <div className="label-header">Tax ID</div>
            <input
              className="main-input-fields"
              type="text"
              name="taxId"
              onChange={handleSectionChange1}
              value={props.values.taxId || ""}
              placeholder="Enter your tax ID"
            />
          </div>

          {/* Invoices Checkbox */}
          <div className="main-label-header">
            <div className="label-header">Invoices</div>
            <label className="checkbox-container">
              <input
                type="checkbox"
                name="emailInvoices"
                onChange={handleSectionChange1}
                checked={props.values.emailInvoices || false}
              />
              I want Email Invoices
            </label>
          </div>

          {/* Submit Button */}
          <button className="update-button" onClick={props.onSubmit}>
            Update Information
          </button>
        </div>
      </div>
    </div>
  );
};

const CoachPayment = () => {
  const [activeLink, setActiveLink] = useState("Billing Information");

  const [formValues, setFormValues] = useState({
    fullName: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    address: "",
    taxId: "",
    emailInvoices: false,
  });

  // Function to handle field changes
  const handleFieldChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    console.log("Form submitted with values:", formValues);
    // Add logic for form submission (e.g., API call)
  };

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  return (
    <div className="billing-dashboard">
      <Sidebar activeLink={activeLink} onLinkClick={handleLinkClick} />
      <MainContent activeLink={activeLink} onLinkClick={handleLinkClick} />
      <div>
        <RightContent
          values={formValues}
          onChange={handleFieldChange}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default CoachPayment;
